<template>
  <div>
    <div class="vx-row mb-12">
      <vs-button v-on:click="create()" icon="done" color="primary"
        >Create Payment Receive</vs-button
      >
    </div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
              <vs-td class="whitespace-no-wrap">
                 <div class="mt-4 flex mr-1">
                  <vx-tooltip text="Edit Payment Receive" class="mr-4">
                    <vs-button
                      color="warning"
                      type="line"
                      icon-pack="feather"
                      @click="edit(tr)"
                      icon="icon-edit"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="View Payment Receive" class="mr-4">
                    <vs-button
                      color="green"
                      type="line"
                      icon-pack="feather"
                      @click="view(tr)"
                      icon="icon-eye"
                    />
                  </vx-tooltip>
                  <!--@click="promptConfirm(tr)"-->
                  <vx-tooltip text="Confirm Supplier Payment" class="mr-4">
                    <vs-button
                      color="green"
                      type="line"
                      icon-pack="feather"
                      @click="promptConfirm(tr)"
                      icon="icon-check-circle"
                    />
                  </vx-tooltip>
                  
                  <vx-tooltip text="Cancel Payment Receive" class="mr-4">
                    <vs-button
                      color="red"
                      type="line"
                      icon-pack="feather"
                      @click="promptCancel(tr)"
                      icon="icon-x-circle"
                    />
                  </vx-tooltip>
                 </div>
              </vs-td>
              <vs-td>
                {{ tr.CustomerCode }} {{ tr.CustomerName }} 
              </vs-td>
              <vs-td>
                Code : {{tr.Code}}
                <br>
                Reference Code : {{tr.ReferenceCode}}
                <br>
                Payment Type : {{typePayment[tr.Type]}}
                <br>
                {{ tr.PaymentMethod }} - {{ tr.PaymentName }}
                <br>
                {{ tr.PaymentAccountOwner }} {{ tr.PaymentAccountNumber }}
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.Amount) }}
              </vs-td>
              <vs-td>
                {{ dateFormat(tr.DatePayment) }}
              </vs-td>
            </vs-tr>
          </template>
          
        </data-table>
      </div>
      <!-- PROMPT CONFIRM -->
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="changeStatus"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="confirmPrompt"
      >
        <div class="con-exemple-prompt">
          Supplier Payment
          <br />
          Are you sure to confirm <b>{{this.selectedData.Code}}</b> supplier payment ?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <!-- PROMPT DELETE -->
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="changeStatus"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="cancelPrompt"
      >
        <div class="con-exemple-prompt">
          Receive Payment
          <br />
          Are you sure to cancel <b>{{this.selectedData.Code}}</b> receive payment ?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <!-- <create @closeDetail="closeDetail" /> -->
             <template v-if="isCreate">
              <create @closeDetail="closeDetail" />
            </template>
            <template v-else-if="isView  && selectedData.ID != 0">
              <viewdata :selected="selectedData" @closeDetail="closeDetail" />
            </template>
            <template v-else>
              <edit :selected="selectedData" @closeDetail="closeDetail" />
            </template>
          
            <!-- <template v-else-if="edit(tr) && selectedData.ID != 0">
              <edit :selected="selectedData" @closeDetail="closeDetail" />
            </template>
            <template v-else>
              <view :selected="selectedData" @closeDetail="closeDetail" />
            </template> -->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { dataPayment } from "../../../../services/api/payment";
import edit from "./edit.vue";
import create from "./create.vue";
import viewdata from "./view.vue";

import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
    isReload: Boolean,
  },
  components: {
    create,
    edit,
    viewdata,
  },
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit","Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
        status:0,
      },
      header: [
        {
          text: "Action",
          value: "action",
          // width: '5%'
        },
        {
          text: "Customer",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      cancelPrompt: false,
      selectedData: {},
      isEdit: false,
      isView: false,
      isCreate: false,
    };
  },
  computed: {},
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
  },
  mounted() {
    // this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    create() {
      this.detail = true;
      this.isEdit = false;
      this.isView = false;
      this.isCreate = true;
    },
    view(selected) {
      console.log("selected open view", selected)
      this.selectedData = selected
      this.detail = true;
      this.isEdit = false;
      this.isView = true;
      this.isCreate = false;
      // console.log("this.isView", this.isView)
    },
    edit(selected) {
      console.log("selected open edit", selected)
      this.selectedData = selected
      this.detail = true;
      this.isEdit = true;
      this.isView = false;
      this.isCreate = false;
      // console.log("this.isEdit", this.isEdit)

    },

    promptConfirm (data) {
      this.confirmPrompt = true
      this.status = 1
      this.cancelPrompt = false
      this.selectedData = data
      console.log("confirm")
    },

    promptCancel (data) {
      this.confirmPrompt = false
      this.status = 0
      this.cancelPrompt = true
      this.selectedData = data
      console.log("confirm")
    },

    // create() {
    //         this.$router.push("/finance/payment/create");

    //     },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    changeStatus () {
      let params = {
        id: this.selectedData.ID,
        status: this.status
      }
      console.log("params changeStatus",params )
      this.$vs.loading;
      this.$http.post('/api/v1/payment/receive/change-status',params).then((result) => { //ganti
        this.$vs.loading.close();
          if (result.code == 200) {
            this.closeDetail()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
          } else {
            this.closeDetail()
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch((e) => {
          console.log(e)
        
      })
    },
    reloadData(params) {
      const territory_ids = this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes = this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.params = { ...params, territory_ids, customer_codes };
      console.log("params",this.params );
      this.$vs.loading();

      const dataTable = dataPayment(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          console.log("r.data.payment", r.data.payment)
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>