var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"}),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.payment),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td'),_c('vs-td',[_vm._v(" "+_vm._s(tr.CustomerCode)+" "+_vm._s(tr.CustomerName)+" ")]),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.Code)+" "),_c('br'),_vm._v(" Reference Code : "+_vm._s(tr.ReferenceCode)+" "),_c('br'),_vm._v(" Payment Type : "+_vm._s(_vm.typePayment[tr.Type])+" "),_c('br'),_vm._v(" "+_vm._s(tr.PaymentMethod)+" - "+_vm._s(tr.PaymentName)+" "),_c('br'),_vm._v(" "+_vm._s(tr.PaymentAccountOwner)+" "+_vm._s(tr.PaymentAccountNumber)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.priceFormat(tr.Amount))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.dateFormat(tr.DatePayment))+" ")])],1)}),1)],2)],1),_c('vs-prompt',{attrs:{"title":"Confirmation","color":"primary","buttons-hidden":false,"active":_vm.confirmPrompt},on:{"accept":_vm.changeStatus,"cancel":_vm.closeDetail,"close":_vm.closeDetail,"update:active":function($event){_vm.confirmPrompt=$event}}},[_c('div',{staticClass:"con-exemple-prompt"},[_vm._v(" Supplier Payment "),_c('br'),_vm._v(" Are you sure to confirm "),_c('b',[_vm._v(_vm._s(this.selectedData.Code))]),_vm._v(" supplier payment ? "),_c('br'),_c('br')])]),_c('vs-prompt',{attrs:{"title":"Confirmation","color":"primary","buttons-hidden":false,"active":_vm.cancelPrompt},on:{"accept":_vm.changeStatus,"cancel":_vm.closeDetail,"close":_vm.closeDetail,"update:active":function($event){_vm.cancelPrompt=$event}}},[_c('div',{staticClass:"con-exemple-prompt"},[_vm._v(" Receive Payment "),_c('br'),_vm._v(" Are you sure to cancel "),_c('b',[_vm._v(_vm._s(this.selectedData.Code))]),_vm._v(" receive payment ? "),_c('br'),_c('br')])]),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1)],1)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }