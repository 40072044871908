<template>
  <!-- <vx-card title="Create Payment"> -->
  <div>
    <vs-divider style="width: 100%; margin-left: 2%">Customer Data</vs-divider>
    <!-- CUSTOMER DATA -->

    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.customer"
          :options="option.customer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="name"
          :loading="loading.customer"
          @search-change="getOptionCustomer"
        >
          <!-- @select="getPaymentAccount" -->
        </multiselect>
      </div>
    </div>
    <!-- SHIP TO -->
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Ship to</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.shipTo"
          :options="option.shipTo"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          track-by="address"
          label="address"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">
                {{ props.option.address }} - {{ props.option.sub_district }} -
                {{ props.option.district }} - {{ props.option.city }} -
                {{ props.option.province }} - {{ props.option.country }} -
                {{ props.option.postal_code }}
              </span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                {{ props.option.address }} - {{ props.option.sub_district }} -
                {{ props.option.district }} - {{ props.option.city }} -
                {{ props.option.province }} - {{ props.option.country }} -
                {{ props.option.postal_code }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
      <!-- <span
          class="text-danger text-sm"
          v-if="territory == null && status_submit"
          >This field is required</span
        > -->
    </div>

    <!-- TERRITORY -->
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="territory" :disabled="true" />
        <!-- <multiselect
            class="selectExample"
            v-model="territory"
            :options="optionTerritory"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            track-by="code"
            label="name"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect> -->
      </div>
      <!-- <span
          class="text-danger text-sm"
          v-if="territory == null && status_submit"
          >This field is required</span
        > -->
    </div>
    <!-- </div> -->
    <!-- PAYMENT DATA -->
    <vs-divider style="width: 100%; margin-left: 2%">Payment Data</vs-divider>

    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Payment Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <flat-pickr
          :config="configDatePicker"
          v-model="create.datePayment"
          style="width: 100%"
        />
      </div>
    </div>

    <!-- PAYMENT METHOD -->
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Payment Method</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.method"
          :options="option.method"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          @select="getPaymentAccount"
        >
        </multiselect>
      </div>
    </div>

    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Payment Account</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.paymentAccount"
          :options="option.paymentAccount"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="BankName"
          label="AccountName"
          @select="getOptionBank"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ paymentMethod[props.option.Type] }}
                {{ props.option.BankName }} - {{ props.option.AccountNumber }})
                {{ props.option.AccountName }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ paymentMethod[props.option.Type] }}
                {{ props.option.BankName }} - {{ props.option.AccountNumber }})
                {{ props.option.AccountName }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <!-- BANK NAME -->
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Bank Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="create.bank" :disabled="true" />
        <!-- <multiselect
          class="selectExample"
          v-model="create.bank"
          :options="option.bankName"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                dt.option.name ? dt.option.name : dt.option.Name
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                dt.option.name ? dt.option.name : dt.option.Name
              }}</span>
            </div>
          </template>
        </multiselect> -->
      </div>
      <!-- <span class="text-danger text-sm" v-if="bank == null && status_submit"
          >This field is required</span
        > -->
    </div>
    <!--BATAS -->

    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Reference Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="create.referenceCode" />
      </div>
    </div>

    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Payment Value</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          v-model="create.amount"
          @keyup="create.amount = formatPrice(create.amount.toString())"
        />
        <!-- @keyup="create.amount = formatPrice(create.amount.toString())" -->
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Note</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="create.note"></vs-textarea>
      </div>
    </div>

   <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.PathFile)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      id="deleteFile"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(tr.ListID)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>

    <vs-divider style="width: 100%; margin-left: 2%"
      >Invoice Data (optional)</vs-divider
    >
    <!-- INVOICE DATA -->
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Invoice Number</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="create.invoiceNumber" />
      </div>
    </div>

    <!-- DEDUCTION DATA -->
    <vs-divider style="width: 100%; margin-left: 2%"
      >Deduction Data (optional)</vs-divider
    >
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Deduction Value</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <!-- <vs-input class="w-full" v-model="create.deductionValue" /> -->
        <vs-input
          class="w-full"
          v-model="create.deductionValue"
          @keyup="create.deductionValue = formatPrice(create.deductionValue.toString())"
        />
      </div>
    </div>

    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 15px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Deduction Reason</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <!-- <vs-input class="w-full" v-model="create.deductionReason" /> -->
        <vs-textarea v-model="create.deductionReason"></vs-textarea>
      </div>
    </div>

    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>

      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Update
      </vs-button>
    </div>
  </div>
  <!-- </vx-card> -->
</template>
<script>
import {
  // dataBank,
  dataPaymentAccount,
  postPayment,
} from "../../../../services/api/payment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    flatPickr,
  },
props: {
    selected: Object,
    // option: Object,
  },
  data() {
    return {
      // 1: Real Account,2: VA, 3: Wallet
      length:0,
      paymentMethod: ["", "Real Account", "VA", "Wallet"],
      file: [],
      fileAttachment:[],
      territory: null, //{ text: "Malang Raya", value: 1 },
      optionTerritory: [{}],
      // optionBankName: [],
      create: {
        referenceCode: null,
        customer: null,
        paymentAccount: null,
        dateApply: "",
        datePayment: null,
        amount: 0,
        note: null,
        // bank: [],
        bank: null,
        // territory: null,
        method: null,
        shipTo: null,
        deductionReason: null,
        deductionValue: 0,
        invoiceNumber: null,
      },
      option: {
        customer: [],
        paymentAccount: [],
        bankName: [],
        shipTo: [],
        // territory: [],
        // method: [],
        method: ["Giro", "Cheque", "Transfer" ], //"Cash"
      },
      loading: {
        customer: false,
      },
      counterLoading: {
        customer: 0,
      },
      configDatePicker: {
        // altFormat: 'F j, Y',
        // altInput: true,
        dateFormat: "d F Y",
      },
    };
  },
  mounted() {
    console.log("mounted")
    console.log("this.selected", this.selected)
    this.create.datePayment = this.selected.DatePayment,
    this.create.method = this.selected.PaymentMethod,
    this.create.bank = this.selected.PaymentName
    
    this.create.referenceCode = this.selected.ReferenceCode
    this.create.amount = this.selected.Amount
    this.create.note = this.selected.Note
    this.getOptionCustomer(this.selected.CustomerCode);
    this.fileAttachment = this.selected.Attachment
    // this.getOptionTerritory(this.selected.TerritoryID);
    this.create.invoiceNumber = this.selected.Info.invoice_number
    this.create.deductionValue = this.selected.Info.deduction_value
    this.create.deductionReason = this.selected.Info.deduction_reason
  },
  watch: {
    "selected.ID" (){
      console.log("this.selected", this.selected)
      this.create.bank = this.selected.PaymentName
      // this.getOptionSupplier(this.selected.PartnerName)
      // this.getOptionSupplierBank()
      // this.create.method = this.selected.PaymentMethodDesc
      // this.create.reference_code = this.selected.ReferenceCode
      // this.create.amount = this.formatPrice(this.selected.Amount)
      // this.create.date_payment = this.selected.DatePayment
      // this.fileAttachment = this.selected.Attachment
      // this.create.date_giro = this.selected.GiroDate
      // this.create.number_of= this.selected.GiroNumber
      // this.create.date_posting = this.selected.DatePosting
      // this.create.note = this.selected.Note
    }
    // "create.bank": function () {
    //   this.getOptionBankName();
    // },
    // "create.customer": function () {
    //   this.getOptionCustomer();
    //   this.getPaymentAccount();
    //   // this.getCustomer();
    //   //   cek value
    //   // console.log(args, this.create.customer)
    // },
    // "create.paymentAccount": function () {
    //   this.getPaymentAccount();
    // },
    // "territory": function () {
    //   this.getOptionTerritory();
    // },
  },
  methods: {
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    // onFromChange(selectedDates, dateStr, instance) {
    // console.log(instance);
    // this.$set(this.configTodateTimePicker, "minDate", dateStr);
    // },
    handleSubmit() {
      if (this.create.customer == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select customer first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      if (this.create.shipTo == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select ship to first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      if (this.territory == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select territory first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      if (this.create.datePayment == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select date payment first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      if (this.create.method == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select payment method first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      if (this.create.paymentAccount == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select payment account first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      if (this.create.bank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select bank name first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      if (this.create.amount == null || this.create.amount == 0 ) {
        this.$vs.notify({
          title: "Error",
          text: "please fill payment value first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      if (this.create.note == null) {
        this.$vs.notify({
          title: "Error",
          text: "please fill note first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      //reference code (optional)
      //attachment
      if (this.fileAttachment.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select file attachment first.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      

      const params = {
        id: this.selected.ID,
        type: 1,
        status: 0,
        customer_code: this.create.customer.code,
        customer_name: this.create.customer.name,
        customer_id: this.create.customer.ID,
        territory_id: this.create.shipTo.territory_id,
        territory_area_id: this.territory_area_id,
        // date_apply: new Date(),
        date_payment: this.create.datePayment,
        payment_method: this.create.method,
        payment_name: this.create.paymentAccount.BankName,
        payment_account_owner: this.create.paymentAccount.AccountName,
        payment_account_number: this.create.paymentAccount.AccountNumber,
        bank_id: this.create.paymentAccount.BankID,
        bank_name: this.create.paymentAccount.BankName,
        shipment_to_address_id : this.create.shipTo.ID,
        // bank account name, bank account number, bank name

        reference_code: this.create.referenceCode,
        amount: parseFloat(this.create.amount.toString().replace(/,/g, "")),
        note: this.create.note,
        //attachmanet
        invoice_number: this.create.invoiceNumber,
        deduction_value: parseFloat(this.create.deductionValue.toString().replace(/,/g, "")),
        deduction_reason: this.create.deductionReason,
      };
      console.log("params", params);

      this.$vs.loading();
      const post = postPayment(params);
      post.then((r) => {
        if (r.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (r.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
          // this.uploadAttachmentAfter(0,r.data.payment.ID)
          // console.log("r.data.payment.ID",r.data.payment.ID )
          // console.log("r.data.ID",r.data.ID )
        }
        this.$nextTick(() => {
          // this.$emit("closeDetail");
          this.$emit("closeDetail", true);
          this.$vs.loading.close();
        });
      });

      // const request = {
      //   type: 1,
      //   customer_code: this.create.customer.code,
      //   customer_name: this.create.customer.name,
      //   payment_method: this.paymentMethod[this.create.paymentAccount.Type],
      //   payment_name: this.create.paymentAccount.BankName,
      //   payment_account_owner: this.create.paymentAccount.AccountName,
      //   payment_account_number: this.create.paymentAccount.AccountNumber,
      //   reference_code: this.create.referenceCode,
      //   date_apply: this.create.datePayment,
      //   date_payment: this.create.datePayment,
      //   amount: parseInt(this.create.amount.replace(/[^,\d]/g, "").toString()),
      //   status: 0,
      // };
      // // console.log(request)
      // var i = true
      // if (i) {
      //   return
      // }
    },
    uploadData(array) {
      console.log(this.lengthFile, this.file,array)
      if (this.lengthFile <= array ) {
          // this.$emit("closeDetail", true);
          // this.$vs.loading.close();
          document.querySelector("#fileInput").value = "";
          return 
      }
      this.$vs.loading();
      this.$http
        .post("/api/v1/other-payment/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.fileAttachment = []
            resp.data.attachment.map(v => {
              this.fileAttachment.push({
                ListID: v.ListID,
                NameFile: v.NameFile,
                PathFile: v.PathFile,
              });
            })
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.uploadData(++array)
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
          this.uploadData(++array)
        });
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selected.ID);
      form.append("file", this.file[array]);
      return form;
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getOptionBankName() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/bank", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.option.bankName = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Bank Name option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        
        this.file = this.$refs.file.files;
        this.fileAttachment = []
        for(let i =0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i])
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        
          
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShow(file) {
      let objectURL
      console.log(file)
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, '_blank').focus();
    },
    handleDeleteAttachment(index) {
      this.fileAttachment = this.fileAttachment.filter((r,i) => {
        return i != index 
      })
      
    },
    uploadAttachmentAfter(i,id) {
      
        if (this.fileAttachment.length == 0) {
          this.$emit("closeDetail", true);
          this.$vs.loading.close();
          return 
        }
        if (this.fileAttachment.length < i ) {
          this.$emit("closeDetail", true);
          this.$vs.loading.close();
          return 
        }
        var form = new FormData();
        form.append("id", id);
        form.append("file", this.fileAttachment[i].File);
        this.$http
          .post("/api/v1/payment/upload-file", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            this.uploadAttachmentAfter(++i,id) 
            // this.$vs.loading.close();
            
          })
          .catch((error) => {
            this.uploadAttachmentAfter(++i,id) 
          });
      // })
    },

    getOptionCustomer(query) {
      // console.log("getOptionCustomer")
      if (query.length <= 2) {
        return;
      }
      let param = {
        search: query,
      };
      this.loading.customer = true;
      this.counterLoading.customer++;
      this.$http
        .get("/api/v1/payment/customer", {
          params: param,
        })
        .then((r) => {
          this.option.customer = r.data.customer;
          // console.log("this.option.customer", this.option.customer)
          this.counterLoading.customer--;
          if (this.counterLoading.customer == 0) {
            this.loading.customer = false;
          }
          this.option.customer.map(v=>{
            if (v.code == this.selected.CustomerCode)
              // console.log("v.Code", v.code)
              // console.log("this.selected.CustomerCode", this.selected.CustomerCode)

              this.create.customer = v
              this.create.customer.ID = v.ID
              // console.log("this.create.customer.ID", this.create.customer.ID)
              // console.log("v", v)
          })
          this.getOptionShipTo(this.selected);
        })
        
        .catch((e) => {
          console.log(e);
        });
    },
    // getOptionTerritory() {
    //   this.$vs.loading();
    //   this.$http
    //     .get("/api/v1/master/territory", {
    //       params: {
    //         order: "name",
    //         sort: "asc",
    //       },
    //     })
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         this.optionTerritory = resp.data.records;
    //       } else {
    //         this.$vs.notify({
    //           title: "Error",
    //           text: "Failed to get Territory option",
    //           color: "danger",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-check",
    //         });
    //       }
    //       this.$vs.loading.close();
    //     });
    // },
    getOptionTerritory(TerritoryID) {
      // console.log("getOptionTerritory")
      // console.log(this.create.shipTo.territory_id);
      // this.create.territory = this.create.shipTo.territory_id;

      // let TerritoryID = this.create.shipTo.territory_id;
      // console.log("ini id", ID);

      this.$http.get("/api/v1/master/territory/get/" + TerritoryID).then((resp) => {
        if (resp.code == 200) {
          this.territory = resp.data.code + " " + resp.data.name;
          this.territory_area_id = resp.data.territory_area_id;
          this.territory_id = resp.data.territory_id;
          console.log("resp.data", resp.data);

        // this.territory.map(v=>{
        //     // if (v.territory_id == this.selected.TerritoryID)
        //     //   console.log("v.ID", v.ID)
        //     //   console.log("this.selected.TerritoryID", this.selected.TerritoryID)

        //     //   this.territory = v
        //       console.log("v", v)
        //   })
        this.getPaymentAccount(this.selected);

        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Ship To Address",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    getOptionShipTo() {
      this.$vs.loading();
      // console.log("this.create.customer.ID", this.create.customer.ID)
      this.$http
        .get("/api/v1/master/customer-address/ship-to/customer", {
          params: {
            search: this.search,
            // length: this.limitShow,
            // page: page,
            customer_id: this.create.customer.ID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.option.shipTo = resp.data.records;
            // console.log("resp.data.records", resp.data.records);
            this.getPaymentAccount();
          this.option.customer.map(v=>{
            if (v.ID == this.create.customer.ID)
              // console.log("v.Code", v.code)
              // console.log("this.selected.CustomerCode", this.selected.CustomerCode)
              this.create.shipTo = v
              // console.log("v", v)
          })
          this.getOptionTerritory(this.selected.TerritoryID);

              // this.create.customer.ID = v.ID
              // console.log("this.create.customer.ID", this.create.customer.ID)
              // console.log("v", v)

          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Ship To Address",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },

    // getOptionShipTo(params) {
    //   console.log("getOptionShipTo")
    //   // console.log("this.create.customer.ID", this.create.customer)
    //   console.log("this.create.customer.ID", this.create.customer)
    //   console.log("params getOptonShipTo", params)
      
    //   // this.create.customer.name
    //   this.$vs.loading();
    //   this.$http
    //     .get("/api/v1/master/customer-address/ship-to/customer/code/" + params )
    //       // params: {
    //       //   search: this.search,
    //       //   // length: this.limitShow,
    //       //   // page: page,
    //       //   customer_id: this.create.customer.ID,
    //       // },
    //     // })
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         this.option.shipTo = resp.data.records;
    //         console.log("this.option.shipTo", this.option.shipTo)
    //         console.log("resp.data.records", resp.data.records);
    //         this.getPaymentAccount();

    //       } else {
    //         this.$vs.notify({
    //           title: "Error",
    //           text: "Failed to get Ship To Address",
    //           color: "danger",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-check",
    //         });
    //       }
    //       this.$vs.loading.close();
    //     });
    // },

    getPaymentAccount() {
      this.$vs.loading();
      // console.log("this.create.customer.code", this.create.customer.code);
      const paymentAccount = dataPaymentAccount(this.create.customer.code);
      paymentAccount.then((response) => {
        this.create.paymentAccount = null;
        this.option.paymentAccount = [];
        response.data.paymentAccount.map((row) => {
          this.option.paymentAccount.push(row);
          // console.log("row", row);
        });
        this.option.paymentAccount.map(v=>{
            if (v.BankName == this.selected.PaymentName)
          //     // console.log("v.Code", v.code)
          //     // console.log("this.selected.CustomerCode", this.selected.CustomerCode)

              this.create.paymentAccount = v
          //     // this.create.shipTo = v

          //     // this.create.customer.ID = v.ID
          //     // console.log("this.create.customer.ID", this.create.customer.ID)
              // console.log("v", v)
          })
        this.$vs.loading.close();
      });
    },

    getOptionBank() {
      // this.create.bank = this.create.paymentAccount.BankName;
    },
  },
};
</script>