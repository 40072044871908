<template>
  <div>
    <vx-card title="Payment Receive">
      <div class="flex flex-col gap-4">
        <operating-unit-filter 
          @onShow="onShow" 
          :hideShowButton="true"
          :multipleTerritory="true"  
        />

        <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
          <customer-filter
            :multiple="true"
            :full="true"
            :showClearButton="true"
          />
        </div>
  
        <div class="flex gap-3 sm:w-2/3 w-full justify-end mt-5 mb-5">
          <vs-button @click="onShow">Show</vs-button>
        </div>

        <vs-tabs :color="colorx" v-model="tabs">
          <vs-tab @click="colorx = 'danger'" label="Open">
            <div class="con-tab-ejemplo">
              <open :isReload="isReload"/>
            </div>
          </vs-tab>
           <vs-tab @click="colorx = 'warning'" label="Waiting Apply">
            <div class="con-tab-ejemplo">
              <apply :isReload="isReload"/>
            </div>
          </vs-tab>
          <!-- <vs-tab @click="colorx = 'success'" label="Waiting Reconcile">
            <div class="con-tab-ejemplo">
              <reconcile />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'primary'" label="Done">
            <div class="con-tab-ejemplo">
              <done />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Waiting Reverse">
            <div class="con-tab-ejemplo">
              <waiting-reverse />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Reversed">
            <div class="con-tab-ejemplo">
              <reversed />
            </div> 
          </vs-tab> -->
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>
<script>
import open from "./open.vue";
import apply from "./waiting-apply.vue";
// import reconcile from "./reconcile.vue";
// import done from "./done.vue";
// import reversed from "./reversed.vue";
// import waitingReverse from "./waiting_reverse.vue";
import operatingUnitFilter from "../../../../components/operatingUnitFilter/operatingUnitFilter.vue";
import customerFilterVue from "../../../../components/customerFilter/customerFilter.vue";
export default {
  components: {
    open,
    apply,
    // reconcile,
    // done,
    // reversed,
    // "waiting-reverse":waitingReverse
    "operating-unit-filter": operatingUnitFilter,
    "customer-filter": customerFilterVue,
  },
  data: () => ({
    colorx: "danger",
    tabs: 0,
    isReload: false,
  }),
  created() {},
  methods: {
    onShow() {
      this.isReload = true;
      this.$nextTick(() => {
        this.isReload = false;
      });
    },
  },
};
</script>
